import React from "react";

export default function DdadCopyright() {
  return (
    <>
      <p>
        &copy;{new Date().getFullYear()} Doggie Doos and Don'ts. All Rights
        Reserved.
      </p>
    </>
  );
}
